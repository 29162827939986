<template>
<div id="page-cremeria-retazo-merma" >
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Ajuste por Retazo, Mermas y Sobrantes" class="elevation-1 px-5 py-3">

          <v-card-text>
            <div id="registros" style="height: 50vh; overflow-y: scroll; overflow-x: scroll;">
                    <v-client-table ref="vuetable" :key="keyTable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                        <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                        </template>
                        <template slot="codigo_barras" slot-scope="props">
                          {{ props.row.codigo_barras.join(", ") }}
                        </template>
                        <template slot="existencia" slot-scope="props">
                          <div style="text-align:center">
                            {{ formatNumberDecCero(props.row.existencia,3) }}
                          </div>
                        </template>
                        <template slot="retazo" slot-scope="props">
                            <v-chip color="blue" label outlined x-small @click="setRetazo(props.row)">
                                {{ (props.row.retazo!=null && props.row.retazo!="")?props.row.retazo:"      " }}
                            </v-chip>
                        </template>
                        <template slot="merma" slot-scope="props">
                            <v-chip color="blue" label outlined x-small @click="setMerma(props.row)">
                                {{ (props.row.merma!=null && props.row.merma!="")?props.row.merma:"      " }}
                            </v-chip>
                        </template>
                        <template slot="sobrante" slot-scope="props">
                            <v-chip color="blue" label outlined x-small @click="setSobrante(props.row)">
                                {{ (props.row.sobrante!=null && props.row.sobrante!="")?props.row.sobrante:"      " }}
                            </v-chip>
                        </template>

                    </v-client-table>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn @click.native="limpiar()">
                    <v-icon>cancel</v-icon> Limpiar
                </v-btn> &nbsp;
                <v-btn color="success" @click.native="enviar()">
                    <v-icon>cancel</v-icon> Enviar
                </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal_retazo" max-width="500px">
    <v-card>
        <v-card-title>
            <v-card class="primary white--text titulomodal">
                <v-icon class="white--text">edit</v-icon>&nbsp;
                <span class="subheading">
                    <strong>RETAZO</strong>
                </span>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="modal_retazo = false" class="close_modal">
                <v-icon class="white--text">cancel</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form ref="formretazo" lazy-validation>
                <v-container>
                    <v-row justify="center">
                        <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                            <v-text-field label="Cantidad" v-model="cantidad_retazo" ref="ingresacantidad"
                            v-on:keydown.enter.prevent='1' @keyup.enter="guardarRetazo()" :rules="[validaCantidadFloatRequerido]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        &nbsp;
                    </v-row>
                    <v-row justify="center">
                        <v-btn @click.native="modal_retazo = false">
                            <v-icon>cancel</v-icon> Cancelar
                        </v-btn>
                        &nbsp;&nbsp;
                        <v-btn color="success" @click.native="guardarRetazo()">
                            <v-icon>done</v-icon> Aceptar
                        </v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

    </v-card>
  </v-dialog>
  <v-dialog v-model="modal_merma" max-width="500px">
    <v-card>
        <v-card-title>
            <v-card class="primary white--text titulomodal">
                <v-icon class="white--text">edit</v-icon>&nbsp;
                <span class="subheading">
                    <strong>MERMA</strong>
                </span>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="modal_merma = false" class="close_modal">
                <v-icon class="white--text">cancel</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form ref="formmerma" lazy-validation>
                <v-container>
                    <v-row justify="center">
                        <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                            <v-text-field label="Cantidad" v-model="cantidad_merma" ref="ingresacantidadmerma"
                            v-on:keydown.enter.prevent='1' @keyup.enter="guardarMerma()" :rules="[validaCantidadFloatRequerido]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        &nbsp;
                    </v-row>
                    <v-row justify="center">
                        <v-btn @click.native="modal_merma = false">
                            <v-icon>cancel</v-icon> Cancelar
                        </v-btn>
                        &nbsp;&nbsp;
                        <v-btn color="success" @click.native="guardarMerma()">
                            <v-icon>done</v-icon> Aceptar
                        </v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="modal_sobrante" max-width="500px">
    <v-card>
        <v-card-title>
            <v-card class="primary white--text titulomodal">
                <v-icon class="white--text">edit</v-icon>&nbsp;
                <span class="subheading">
                    <strong>SOBRANTE</strong>
                </span>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="modal_sobrante = false" class="close_modal">
                <v-icon class="white--text">cancel</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form ref="formsobrante" lazy-validation>
                <v-container>
                    <v-row justify="center">
                        <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                            <v-text-field label="Cantidad" v-model="cantidad_sobrante" ref="ingresacantidadsobrante"
                            v-on:keydown.enter.prevent='1' @keyup.enter="guardarSobrante()" :rules="[validaCantidadFloatRequerido]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        &nbsp;
                    </v-row>
                    <v-row justify="center">
                        <v-btn @click.native="modal_sobrante = false">
                            <v-icon>cancel</v-icon> Cancelar
                        </v-btn>
                        &nbsp;&nbsp;
                        <v-btn color="success" @click.native="guardarSobrante()">
                            <v-icon>done</v-icon> Aceptar
                        </v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

    </v-card>
  </v-dialog>

</div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.retazo')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
        this.$router.go();            
    }
    this.getRegistros();

  },
  data() {
    return {
      keyTable:"retazocremeria",
      sucursal_activa:"",
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      active_tab: "datos",
      menu1: false,
      fecha_parsed: "",
      marcas: [],
      productos: [],
      unidades: [],
      articulos: [],
      impuestos: [],
      categorias: [],
      tipos_precios: [],
      fab: [],
      name: "datagrid",
      fechaUsuario: "",
      columns: [
        "codigo_barras",
        "nombre",
        "existencia",
        "retazo",
        "merma",
        "sobrante"
      ],
      show_loading: true,
      options: {

        filterable: ["codigo_barras", "nombre", "existencia", "retazo","merma","sobrante"],
        sortable: ["codigo_barras", "nombre", "existencia", "retazo","merma","sobrante"],

        headings: {
          "codigo_barras": "Código de Barras",
          "existencia":"Existencia",
          "retazo":"Ajuste X Retazo",
          "merma":"Ajuste X Merma",
          "sobrante":"Ajuste X Sobrante"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          disabled: true,
          href: ""
        },
        {
          text: "Captura de Retazo, Merma y Sobrante",
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        _id: "",
        _rev: "",
        type:"articulos",
        codigo_barras: "",
        nombre: "",
        created_at: "",
        estatus: "Activo",
        descripcion: "",
        categoria: "",
        caducidad: "",
        tiene_impuestos: "",
        tiene_grupo: "",
        marca: "",
        precios_venta: [],
        impuestos: [],
        grupo_articulos: [],
        producto_servicio: "",
        unidad_medida: "",
        stock: {},
      },
      stock:{
        minimo:"",
        maximo:"",
        reorden:""
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },
      modal_retazo:false,
      modal_merma:false,
      modal_sobrante:false,

      cantidad_retazo:0,
      cantidad_merma:0,
      cantidad_sobrante:0,
      codigo_selected:"",

    };
  },

  methods: {
    setRetazo:function(row){
        this.codigo_selected = row.codigo_barras[0];
        this.cantidad_retazo = row.retazo;
        this.modal_retazo = true;
        setTimeout(() => {
            this.$refs.ingresacantidad.focus();
        }, 100);
    },
    guardarRetazo:function(){
        var self = this;
        if (this.$refs.formretazo.validate()) {
            for(var k in self.registros.items){
                var record = self.registros.items[k];
                if( record.codigo_barras.includes(this.codigo_selected)){
                    record.retazo = this.cantidad_retazo;
                    this.modal_retazo = false;
                    this.refreshtable();
                    break;
                }
            }
        }
    },
    formatNumberDecCero: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            numero = 0;
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else{
          if(parseFloat(numero) == parseInt(numero))
            return numero.toString().replace(/\d(?=(\d{3}))/g, '$&,');
          else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
            
    },
    refreshtable:function(){      
        this.keyTable = moment(new Date()).format("x"); //Refrescar tabla        
    },
    setMerma:function(row){
        this.codigo_selected = row.codigo_barras[0];
        this.cantidad_merma = row.merma;
        this.modal_merma = true;
        setTimeout(() => {
            this.$refs.ingresacantidadmerma.focus();
        }, 100);
    },
    guardarMerma:function(){
        var self = this;
        if (this.$refs.formmerma.validate()) {
            for(var k in self.registros.items){
                var record = self.registros.items[k];
                if( record.codigo_barras.includes(this.codigo_selected)){
                    record.merma = this.cantidad_merma;
                    this.modal_merma = false;
                    this.refreshtable();
                    break;
                }
            }
        }
    },
    setSobrante:function(row){
        this.codigo_selected = row.codigo_barras[0];
        this.cantidad_sobrante = row.sobrante;
        this.modal_sobrante = true;
        setTimeout(() => {
            this.$refs.ingresacantidadsobrante.focus();
        }, 100);
    },
    guardarSobrante:function(){
        var self = this;
        if (this.$refs.formsobrante.validate()) {
            for(var k in self.registros.items){
                var record = self.registros.items[k];
                if( record.codigo_barras.includes(this.codigo_selected)){
                    record.sobrante = this.cantidad_sobrante;
                    this.modal_sobrante = false;
                    this.refreshtable();
                    break;
                }
            }
        }
    },
    limpiar: function(){
        var self = this;
        for(var k in self.registros.items){
            var record = self.registros.items[k];
            record.retazo = "";
            record.merma = "";
            record.sobrante = "";
        }
        this.refreshtable();
    },
    enviar: function(){
        window.dialogLoader.show('Espere un momento por favor..');

        var self = this;

        var datos = [];
        for(var k in self.registros.items){
            var record = self.registros.items[k];
            var dato = {"id_articulo":record._id, "nombre":record.nombre, "codigo_barras":record.codigo_barras,
                        "ajuste":"", "descripcion":"", "estatus":"Pendiente", "precio_compra":record.precio_compra};
            if(record.retazo != ""){
                var retazo = JSON.parse(JSON.stringify(dato));
                retazo["ajuste"] = record.retazo;
                retazo["descripcion"] = "RETAZO";
                datos.push(retazo);
            }
            if(record.merma != ""){
                var merma = JSON.parse(JSON.stringify(dato));
                merma["ajuste"] = record.merma;
                merma["descripcion"] = "MERMA";
                datos.push(merma);
            }
            if(record.sobrante != ""){
                var sobrante = JSON.parse(JSON.stringify(dato));
                sobrante["ajuste"] = record.sobrante;
                sobrante["descripcion"] = "SOBRANTE";
                datos.push(sobrante);
            }
        }
        //console.log("RECORDS: ", datos);

        if(datos.length > 0){
            let data = {
                type: "ajustes_cremeria",
                id_sucursal: this.sucursal_activa,
                nombre_sucursal: this.$local_storage.get("sb_nombre_sucursal",""),
                usuario: this.$local_storage.get("sb_usuario",""),
                nombre_usuario: this.$local_storage.get("sb_nombre_usuario",""),
                fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                articulos: datos,
                estatus:"Pendiente", //Pendiente, Aplicado
                usuario_aplico: "",
                nombre_usuario_aplico: "",
            };

            window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
            .then(response => {
                window.dialogLoader.showSnackbar('Los datos se enviaron correctamente..', {
                    color: 'success'
                });
                this.limpiar();
            })
            .catch(error => {
                console.log(error);
                this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar los datos. Intente nuevamente",
                footer: ""
                });
            }).then(() => {
                window.dialogLoader.hide();
            });
        } else {
            window.dialogLoader.hide();
            this.$swal({
                type: "warning",
                title: "No ha ingresado datos.",
                text: "Favor de Verificar",
                footer: ""
            });
        }

    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    validaCantidadFloatRequerido: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return "Debe ser mayor que cero";
        } else if (value != null && value == "")
            return "Campo requerido";
        return true;
    },
    validaFloat: function(campo) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      let value = this.model[campo];
      if (!pattern.test(value)) {
        this.model[campo] = this.model[campo].substring(0, this.model[campo].length - 1);
        this.validaFloat(campo);
        //console.log("INVALIDO")
      }
      //else
      //console.log("VALIDO");

    },
    validaCantidadFloat: function(value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return true;
        }
        return true;
    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad entera no válida"
      } else
        return true;

    },
    requiredObject: function(val) {
      if (val != null && val.nombre != null && val != "") {
        return true;
      } else
        return "Este campo es requerido";
    },
    requiredArray: function(val) {
      if (val != null && val.length > 0) {
        return true;
      } else
        return "Este campo es requerido";
    },
    validaFecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida.";
      else
        return true;
    },
    validaNombre: function(val) {
      if (val != null) {
        if (this.esCampoUnico("nombre", val) == false)
          return "El nombre " + val + " ya se encuentra registrado.";
      }
      return true;
    },
    validaCodigo: function(val) {
      if (val != null) {
        if (this.esCampoUnico("codigo_barras", val) == false)
          return "El código " + val + " ya se encuentra registrado.";
      }
      return true;
    },

    esCampoUnico: function(campo, valor) {
      var filter = [];
      if (this.update) {
        var filter = this.registros.items.find(e => e[campo].toString().trim() == valor.toString().trim() && e._id != this.model._id);
      } else {
        var filter = this.registros.items.find(e => e[campo].toString().trim() == valor.toString().trim());
      }
      if (filter) {
        return false;
      }
      return true;
    },

    concatenedProducto: function(val) {
      return val.clave + " - " + val.nombre;
    },

    getRegistros: function(id_registro) {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        let data = {
            "selector":{
                "type":"articulos",
                "estatus":"Activo",
                "categoria": {"$in":["CARNES FRIAS"]},
                "nombre":{"$exists":true},
            },
            "fields":["_id","codigo_barras","nombre","precio_compra"],            
            "sort": ["type","estatus","nombre","categoria"],
            "use_index":"_design/3dd1469c51121477f9205d9829aece1e392c47de"
        };

        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/';

        axios.post(url, data)
        .then(async response => {

            self.registros.items = response.data.docs;
            await Promise.all(self.registros.items.map(async (x) => {
                x["retazo"] = "";
                x["merma"] = "";
                x["sobrante"] = "";
                x["existencia"] = 0;
                await window.funciones_lotes.consultaExistencia({"id_articulo":x._id, "id_sucursal":this.sucursal_activa }).then(result => {
                  if (result.length > 0) {
                    result.forEach(el => {
                      x["existencia"] += el.existencia
                    });
                  }
                }).catch(err => {
                  console.log("Error al consultar existencia: ", err)
                });                

            }));
            this.refreshtable();            

        }).catch( error => {
            console.log(error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar articulos. Intente nuevamente.",
                footer: ""
                });
        }).then(()=>{
            window.dialogLoader.hide();
        });
    },

  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: black;
  font-size: small;
  background-color: #EEEEEE;
  padding: 0px;
}

.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  color: white;
  font-size: small;
  background-color: #df7205;
  padding: 0px;
  font-weight: bold;

}

.v-tabs-slider-wrapper {
  left: 0 !important;
}

.table-bordered thead th,
.table-bordered thead td {
  font-size: x-small !important;
}
</style>
